<template>
  <div class="test">
    <div class="test-title-Wrapper clip">
      <h1 ref="titleRef" class="anim-slide-up-init anim-slide-up-toggle">
        Virtual Vorpal Vexer
      </h1>
    </div>
    <div class="test-subtitle-wrapper">
      <h4 ref="h4Ref" class="slide-down-anim">
        Digital Mechanic and Demolitioner
      </h4>
    </div>
    <div @click="clickHandler" class="test-button button cursor">Click Me</div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const titleRef = ref();
    const h4Ref = ref();

    const clickHandler = () => {
      console.log("click on the button");
      h4Ref.value.classList.toggle("slide-down-anim");
      titleRef.value.classList.toggle("anim-slide-up-toggle");
    };

    return { clickHandler, h4Ref, titleRef };
  },
};
</script>

<style lang='scss'>
.test {
  border: solid white 1px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
  position: relative;

  h1 {
    font-size: 3rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  .anim-opacity-init {
    opacity: 1;
    transition: opacity 1s ease;
  }

  .anim-opacity-toggle {
    opacity: 0;
  }

  .clip {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .anim-slide-up-init {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(0);

    transition: transform 1s ease;
  }

  .anim-slide-up-toggle {
    transform: translateY(100%);
  }

  &-title-Wrapper {
    margin: 2rem 0;
    border: solid white 1px;

    // needed for the anim
    width: 100%;
    height: 3rem;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-subtitle-wrapper {
    border: solid white 1px;
    position: relative;
    width: 100%;
    height: 2rem;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

    h4 {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(0);

      transition: transform 1s ease;
    }

    .slide-down-anim {
      transform: translateY(-100%);
    }
  }

  &-button {
    margin: 1rem 0;
  }
}
</style>